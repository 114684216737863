var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "a b c d e f g T h H",
      "2": "C K L G M N O",
      "1028": "Y Z",
      "1540": "P Q R S V W X"
    },
    C: {
      "1": "RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB",
      "164": "0 1 2 3 4 gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z tB uB",
      "1540": "5 6 7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB"
    },
    D: {
      "1": "a b c d e f g T h H kB vB wB",
      "292": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB",
      "1028": "Y Z",
      "1540": "UB VB WB XB YB ZB aB bB cB dB P Q R S V W X"
    },
    E: {
      "1": "G 4B nB oB 5B",
      "292": "I i J D E F A B C xB lB yB zB 0B 1B mB eB",
      "1028": "3B",
      "1540": "K L fB 2B"
    },
    F: {
      "1": "bB cB dB P Q R jB S",
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "292": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB",
      "1028": "ZB aB",
      "1540": "KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB"
    },
    G: {
      "1": "UC nB oB",
      "292": "E lB BC qB CC DC EC FC GC HC IC JC KC LC MC",
      "1028": "TC",
      "1540": "NC OC PC QC RC SC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "1": "H",
      "292": "gB I WC XC YC ZC qB aC bC"
    },
    J: {
      "292": "D A"
    },
    K: {
      "1": "U",
      "2": "A B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "292": "cC"
    },
    P: {
      "1": "mC nC",
      "292": "I dC eC fC gC hC",
      "1540": "mB iC jC kC lC"
    },
    Q: {
      "1540": "oC"
    },
    R: {
      "1540": "pC"
    },
    S: {
      "1540": "qC"
    }
  },
  B: 5,
  C: "CSS Logical Properties"
};
export default exports;